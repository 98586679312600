<template>
  <div>
    <MonthlyPayments :tab-name="tabName"></MonthlyPayments>
  </div>
</template>
<script>
import MonthlyPayments from "@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue";

export default {
  components: {
    MonthlyPayments,
  },
  data() {
    return {
      tabName: "monthly-payments",
    };
  },
};
</script>